<template>
  <fragment>
    <h2>Form Elements</h2>

    <v-card>
      <h3>h-text-field</h3>
      <div style="max-width: 500px">
        <h-text-field label="Label Text" :rules="isRequiredTextfield" :hint="hintTextfield" :error="radioGroupTextfield === 'error'" :disabled="radioGroupTextfield === 'disabled'" persistent-hint />

        <v-radio-group v-model="radioGroupTextfield" row>
          <v-radio label="Normal" value="normal"></v-radio>

          <v-radio label="Error" value="error"></v-radio>

          <v-radio label="Show hint" value="hint"></v-radio>

          <v-radio label="Disabled" value="disabled"></v-radio>
        </v-radio-group>
      </div>

      <h3>h-text-field with "prepend-inner-icon" and "append-icon" props used</h3>
      <div style="max-width: 500px">
        <h-text-field label="Text field with a append icon" append-icon="mdi-magnify" />
      </div>
      <div style="max-width: 500px">
        <h-text-field label="Text field with a prepended icon" prepend-inner-icon="mdi-magnify" />
      </div>
      <div style="max-width: 500px">
        <h-text-field
          label="Text field with clickable icons"
          hint="Click on the ? icons left or right"
          persistent-hint
          append-icon="mdi-help-circle-outline"
          @click:append="modalIsOpen = true"
          prepend-inner-icon="mdi-help-circle-outline"
          @click:prepend-inner="modalIsOpen = true"
        />
      </div>

      <h3>Date Picker Example</h3>
      <div style="max-width: 500px">
        <v-menu content-class="elevation-4" v-model="datePickerMenu" transition="slide-y-transition" offset-y nudge-top="20px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <h-text-field v-model="date" v-bind="attrs" v-on="on" label="Select a date" append-icon="mdi-calendar" readonly />
          </template>
          <v-date-picker v-model="date" ref="birthDatePicker" color="primary" :locale="currentLocale" width="260" no-title :max="new Date().toISOString()" @input="datePickerMenu = false" />
        </v-menu>
      </div>
    </v-card>

    <v-card>
      <h3>h-text-area</h3>
      <div style="max-width: 500px">
        <h-textarea label="Label Text" :rules="isRequiredTextarea" :hint="hintTextarea" :error="radioGroupTextarea === 'error'" :disabled="radioGroupTextarea === 'disabled'" persistent-hint />

        <v-radio-group v-model="radioGroupTextarea" row>
          <v-radio label="Normal" value="normal"></v-radio>

          <v-radio label="Error" value="error"></v-radio>

          <v-radio label="Show hint" value="hint"></v-radio>

          <v-radio label="Disabled" value="disabled"></v-radio>
        </v-radio-group>
      </div>
    </v-card>

    <v-card style="padding-bottom: 200px">
      <h3>h-select</h3>
      <div style="max-width: 500px">
        <h-select label="Select Month" :items="selectItems" :rules="isRequiredSelect" :hint="hintSelect" :error="radioGroupSelect === 'error'" :disabled="radioGroupSelect === 'disabled'" persistent-hint />
      </div>
      <div>
        <v-radio-group v-model="radioGroupSelect" row>
          <v-radio label="Normal" value="normal"></v-radio>

          <v-radio label="Error" value="error"></v-radio>

          <v-radio label="Show hint" value="hint"></v-radio>

          <v-radio label="Disabled" value="disabled"></v-radio>
        </v-radio-group>
      </div>
    </v-card>

    <v-card>
      <h3>h-checkbox</h3>
      <div class="checkbox-container">
        <h-checkbox label="Standard"></h-checkbox>
        <h-checkbox label="Error" error></h-checkbox>
        <h-checkbox label="Success" success></h-checkbox>
        <h-checkbox label="Disabled" disabled></h-checkbox>
        <h-checkbox label="Indeterminate" indeterminate></h-checkbox>
      </div>
    </v-card>

    <h-dialog v-model="modalIsOpen" title="It Works!" />
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'form-panel',
  data() {
    return {
      radioGroupTextfield: 'normal',
      radioGroupTextarea: 'normal',
      radioGroupSelect: 'normal',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },

      // Select
      selectItems: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

      modalIsOpen: false,
      date: null,
      datePickerMenu: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),

    isRequiredTextfield() {
      return this.radioGroupTextfield === 'error' ? [this.rules.required] : [];
    },
    hintTextfield() {
      return this.radioGroupTextfield === 'hint' ? 'Need a hint? I can help' : '';
    },
    isRequiredSelect() {
      return this.radioGroupSelect === 'error' ? [this.rules.required] : [];
    },
    hintSelect() {
      return this.radioGroupSelect === 'hint' ? 'Need a hint? I can help' : '';
    },

    isRequiredTextarea() {
      return this.radioGroupTextarea === 'error' ? [this.rules.required] : [];
    },
    hintTextarea() {
      return this.radioGroupTextarea === 'hint' ? 'Need a hint? I can help' : '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
