<template>
  <fragment>
    <h2>Vuetify Theme Colors</h2>
    <v-card v-for="(color, index) in vuetifyThemeColors" :key="`vuetifyColor-${index}`">
      <h3>{{ color }}</h3>
      <div class="color-box">
        <div class="color-tile" :style="`background: var(--color-${color})`"></div>
        <p>
          CSS Variable: --color-{{ color }}<br />
          Value: <css-var-value :css-var="`--color-${color}`" />
        </p>
      </div>
    </v-card>

    <h2>Other Colors</h2>

    <v-card>
      <h3>Secondary Text</h3>
      <div class="color-box">
        <div class="color-tile" style="background: var(--color-text-secondary)"></div>
        <p>
          CSS Variable: --color-text-secondary<br />
          Value: <css-var-value css-var="--color-text-secondary" />
        </p>
      </div>
    </v-card>

    <v-card>
      <h3>Background Alt</h3>
      <div class="color-box">
        <div class="color-tile" style="background: var(--color-background-alt)"></div>
        <p>
          CSS Variable: --color-background-alt<br />
          Value: <css-var-value css-var="--color-background-alt" />
        </p>
      </div>
    </v-card>

    <v-card>
      <h3>Border</h3>
      <div class="color-box">
        <div class="color-tile" style="background: var(--color-border)"></div>
        <p>
          CSS Variable: --color-border<br />
          Value: <css-var-value css-var="--color-border" />
        </p>
      </div>
    </v-card>

    <v-card>
      <h3>Border Light</h3>
      <div class="color-box">
        <div class="color-tile" style="background: var(--color-border-light)"></div>
        <p>
          CSS Variable: --color-border-light<br />
          Value: <css-var-value css-var="--color-border-light" />
        </p>
      </div>
    </v-card>

    <h2>Branding Colors CSS Custom Properties (CSS vars)</h2>
    <p>Branding color variables are generated in the App.vue file. The default branding color is black.</p>

    <v-card>
      <h3>Default branding color</h3>
      <p>The "--color-branding" variable contains a HEX color value which can be used in your CSS/SCSS using the var() function.</p>
      <div class="color-box">
        <div class="color-tile" style="background-color: var(--color-branding)"></div>
        <p>
          CSS Variable: --color-branding<br />
          Value: <css-var-value css-var="--color-branding" />
        </p>
      </div>
      <code>background-color: var(--color-branding);</code>
    </v-card>

    <v-card>
      <h3>Branding color with transparency</h3>
      <p>To add transparency to the branding color, the "--color-branding-rgb-values" can be used. This variable contains 3 comma seperated values. It can be used within a RGBA() function with a manually added opacity value.</p>
      <div class="color-box">
        <div class="color-tile" style="background-color: rgba(var(--color-branding-rgb-values), 0.5)"></div>
        <p>
          CSS Variable: --color-branding-rgb-values<br />
          Value: <css-var-value css-var="--color-branding-rgb-values" />
        </p>
      </div>
      <code>background-color: rgba(var(--color-branding-rgb-values), .5);<br />// This will render as rgba(0, 0, 0, .5)</code>
    </v-card>
  </fragment>
</template>

<script>
import cssVarValue from '@/components/styleguide/cssVarValue';

export default {
  name: 'color-panel',
  components: { cssVarValue },
  data() {
    return {
      vuetifyThemeColors: ['primary', 'secondary', 'accent', 'success', 'warning', 'error', 'ultra-light', 'text-primary'],
    };
  },
  methods: {
    dynamicBrandingColor(index) {
      return '--color-branding:' + this.brandingColors[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.color-box {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  p {
    margin: 0 0 0 12px;
  }
}
h3 {
  text-transform: capitalize;
}
.color-tile {
  width: 100px;
  height: 50px;
  border: 1px solid var(--color-border-light);
}
code {
  margin-top: 12px;
  display: block;
}
</style>
