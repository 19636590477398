<template>
  <fragment>
    <h2 class="mb-2">Spacing</h2>
    <div>
      <div>
        Your current breakpoint: <span class="dynamic-value">{{ $vuetify.breakpoint.name }}</span>
      </div>
      <div>
        Your current viewport width: <span class="dynamic-value">{{ $vuetify.breakpoint.width }}</span>
      </div>

      <h3 class="mt-4 mb-1">Gap Variables Values</h3>
      <div class="gap-container">
        <div>--gap-xxsmall: <css-var-value css-var="--gap-xxsmall" /></div>
        <div style="height: var(--gap-xxsmall); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap-xsmall: <css-var-value css-var="--gap-xsmall" /></div>
        <div style="height: var(--gap-xsmall); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap-small: <css-var-value css-var="--gap-small" /></div>
        <div style="height: var(--gap-small); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap: <css-var-value css-var="--gap" /></div>
        <div style="height: var(--gap); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap-large: <css-var-value css-var="--gap-large" /></div>
        <div style="height: var(--gap-large); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap-xlarge: <css-var-value css-var="--gap-xlarge" /></div>
        <div style="height: var(--gap-xlarge); background: var(--color-primary)"></div>
      </div>
      <div class="gap-container">
        <div>--gap-xxlarge: <css-var-value css-var="--gap-xxlarge" /></div>
        <div style="height: var(--gap-xxlarge); background: var(--color-primary)"></div>
      </div>

      <div class="gap-container mt-8">
        <div>--gap-page-side: <css-var-value css-var="--gap-page-side" /></div>
        <div style="height: var(--gap-page-side); background: var(--color-primary)"></div>
      </div>
    </div>

    <div class="demo-container">
      <div class="intro-box">
        <h1>Spacing Variables Demo</h1>
        <p class="h-heading-h4">Subheader Text ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
      </div>

      <div class="hello-card-box">
        <div style="width: 50%; min-width: 300px">
          <h2>Your Hello Card</h2>
          <p>You need to apply for your Hello Visa card. All your balances will be automatically transferred and you will be able to use them in stores with the card as you usually do with your credit or debit card.</p>
          <h-btn color="primary" inverted>Get my card</h-btn>
        </div>
      </div>

      <div class="border-box">
        <h2>Border Box Title</h2>
        <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
        <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
      </div>

      <h2 class="full-width-header">Here is a liste of stuff</h2>
      <ul class="demo-list">
        <li class="demo-list-item">
          <h3>First item of the list</h3>
          <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
        </li>
        <li class="demo-list-item">
          <h3>Second item of the list</h3>
          <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
        </li>
        <li class="demo-list-item">
          <h3>Third item of the list</h3>
          <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
        </li>
      </ul>

      <h2 class="full-width-header">Here is a grid of cards</h2>
      <div class="demo-grid">
        <div class="demo-card">
          <img src="https://via.placeholder.com/400x180.png?text=Placeholder+Image" />
          <div class="demo-card-content">
            <div class="demo-card-inner">
              <h3>Card Title</h3>
              <div class="content-gap"></div>
              <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
              <div class="content-gap"></div>
              <h-btn block>See offer</h-btn>
            </div>
          </div>
        </div>
        <div class="demo-card">
          <img src="https://via.placeholder.com/400x180.png?text=Placeholder+Image" />
          <div class="demo-card-content">
            <div class="demo-card-inner">
              <h3>Card Title</h3>
              <div class="content-gap"></div>
              <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
              <div class="content-gap"></div>
              <h-btn block>See offer</h-btn>
            </div>
          </div>
        </div>
        <div class="demo-card">
          <img src="https://via.placeholder.com/400x180.png?text=Placeholder+Image" />
          <div class="demo-card-content">
            <div class="demo-card-inner">
              <h3>Card Title</h3>
              <div class="content-gap"></div>
              <p>Lorem ipsum dolor sit amet. Non unde incidunt et repellat dolore aut iste obcaecati ea sunt nulla et nemo dolores. Et soluta saepe et commodi provident non mollitia eius et error!</p>
              <div class="content-gap"></div>
              <h-btn block>See offer</h-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import cssVarValue from '@/components/styleguide/cssVarValue';

export default {
  name: 'spacing-panel',
  components: { cssVarValue },
};
</script>

<style lang="scss" scoped>
.gap-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > :first-child {
    width: 350px;
    margin-right: 20px;
  }

  > :last-child {
    width: 100px;
  }
}

.demo-container {
  margin-top: var(--gap);
  border-top: var(--border-thin) solid var(--color-border);

  h1,
  h2,
  h3 {
    margin: 0 0 var(--gap);
  }
}

.intro-box {
  padding: var(--gap) var(--gap-page-side);

  h1 {
    margin-bottom: var(--gap);
  }
}

.hello-card-box {
  padding: var(--gap) var(--gap-page-side);
  background-color: var(--color-primary);
  color: white;
}

.border-box {
  padding: var(--gap);
  margin: var(--gap) var(--gap-page-side);
  border: var(--border-thin) solid var(--color-border);
  border-radius: 10px;

  h2 {
    margin-bottom: var(--gap);
  }
}

h2.full-width-header {
  padding: var(--gap-small) var(--gap-page-side);
  background-color: var(--color-primary);
  color: white;
}
.demo-list {
  padding: 0 var(--gap-page-side) var(--gap);
  list-style: none;
}
.demo-list-item {
  padding: var(--gap-small) 0;
  border-bottom: var(--border-thin) solid var(--color-border);

  &:last-child {
    border: none;
  }
  h3 {
    margin-bottom: var(--gap-xsmall);
  }
}

.demo-grid {
  display: grid;
  margin: var(--gap) var(--gap-page-side);
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
  grid-template-rows: 1fr;
}

.demo-card {
  border: var(--border-thin) solid var(--color-border);

  img {
    display: block;
    width: 100%;
  }

  .demo-card-content {
    padding: 20px;
  }

  .demo-card-inner {
    background-color: white;
  }
}
</style>
