<template>
  <fragment>
    <h2>Buttons</h2>
    <v-card>
      <h3>h-btn with Vuetify theme colors</h3>
      <h-btn>Primary</h-btn>
      <h-btn color="secondary">secondary</h-btn>
      <h-btn color="accent">accent</h-btn>
      <h-btn color="success">success</h-btn>
      <h-btn color="warning">warning</h-btn>
      <h-btn color="error">error</h-btn>
      <h-btn color="text-primary">text-primary</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn with Vuetify theme colors and outlined</h3>
      <h-btn outlined>Primary</h-btn>
      <h-btn color="secondary" outlined>secondary</h-btn>
      <h-btn color="accent" outlined>accent</h-btn>
      <h-btn color="success" outlined>success</h-btn>
      <h-btn color="warning" outlined>warning</h-btn>
      <h-btn color="error" outlined>error</h-btn>
      <h-btn color="text-primary" outlined>text-primary</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn - Adding the prop "inverted" will apply the selected color to text and a white background.</h3>
      <p>Works only with the Vuetify theme colors above. For example, having color="#ff00ff" with the inverted prop won't work.</p>
      <h-btn inverted>Primary</h-btn>
      <h-btn color="secondary" inverted>secondary</h-btn>
      <h-btn color="accent" inverted>accent</h-btn>
      <h-btn color="success" inverted>success</h-btn>
      <h-btn color="warning" inverted>warning</h-btn>
      <h-btn color="error" inverted>error</h-btn>
      <h-btn color="text-primary" inverted>text-primary</h-btn>
      <h-btn color="text-primary" text disabled>text-primary-disable</h-btn>
    </v-card>
    <v-card style="background: #81e1f4">
      <h3>h-btn - Adding the prop "text" will apply the selected color to text and a white background.</h3>
      <p>Works only with the Vuetify theme colors above. For example, having color="#ff00ff" with the inverted prop won't work.</p>
      <h-btn text>Primary</h-btn>
      <h-btn color="secondary" text>secondary</h-btn>
      <h-btn color="accent" text>accent</h-btn>
      <h-btn color="success" text>success</h-btn>
      <h-btn color="warning" text>warning</h-btn>
      <h-btn color="error" text>error</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn - Adding the prop "rounded"</h3>
      <h-btn rounded>Primary</h-btn>
      <h-btn rounded color="secondary">secondary</h-btn>
      <h-btn rounded color="accent">accent</h-btn>
      <h-btn rounded color="success">success</h-btn>
      <h-btn rounded color="warning">warning</h-btn>
      <h-btn rounded color="error">error</h-btn>
      <h-btn rounded color="text-primary">text-primary</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn - Adding the prop "rounded" and "outlined"</h3>
      <h-btn outlined rounded>Primary</h-btn>
      <h-btn outlined rounded color="secondary">secondary</h-btn>
      <h-btn outlined rounded color="accent">accent</h-btn>
      <h-btn outlined rounded color="success">success</h-btn>
      <h-btn outlined rounded color="warning">warning</h-btn>
      <h-btn outlined rounded color="error">error</h-btn>
      <h-btn outlined rounded color="text-primary">text-primary</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn Sizes</h3>
      <h-btn x-small>x-small</h-btn>
      <p class="mb-2 mt-2">Small is the closest thing to Quyen's button-1. The difference is this font-size doesn't change</p>
      <h-btn small>small (button-1)</h-btn>
      <h-btn>medium</h-btn>
      <p class="mb-2 mt-2">Large is the closest thing to Quyen's button-2. The difference is this font-size doesn't change</p>
      <h-btn large>large (button-2)</h-btn>
      <h-btn x-large>x-large</h-btn>
    </v-card>
    <v-card>
      <h3>h-btn Disabled State</h3>
      <h-btn disabled>disabled</h-btn>
      <h-btn disabled outlined>disabled outlined</h-btn>
      <h-btn text disabled>text-primary-disable</h-btn>
      <h-btn inverted disabled>inverted-primary-disable</h-btn>
    </v-card>
    <v-card>
      <h3>h-link with Vuetify theme colors</h3>
      <h-link>Primary</h-link>
      <h-link color="secondary">Secondary</h-link>
      <h-link color="accent">Accent</h-link>
      <h-link color="success">Success</h-link>
      <h-link color="warning">Warning</h-link>
      <h-link color="error">Error</h-link>
      <h-link color="text-primary">text-primary</h-link>
    </v-card>
    <v-card>
      <h3>h-link with custom prop "bold"</h3>
      <h-link bold>Primary Bold</h-link>
    </v-card>
    <v-card>
      <h3>h-link with custom prop "extra-bold"</h3>
      <h-link extra-bold>Primary Extra Bold</h-link>
    </v-card>
    <v-card>
      <h3>h-link with icon after text</h3>
      <h-link>
        Link With Icon
        <v-icon>mdi-open-in-new</v-icon>
      </h-link>
    </v-card>
    <v-card>
      <h3>h-link disabled</h3>
      <h-link disabled> Link Disabled </h-link>
    </v-card>
  </fragment>
</template>

<script>
export default {
  name: 'button-panel',
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
}
</style>
