<template>
  <div>
    <h1>Styleguide</h1>

    <v-tabs v-model="tab">
      <v-tab>Colors</v-tab>
      <v-tab>Buttons</v-tab>
      <v-tab>Form Elements</v-tab>
      <v-tab>Spacing</v-tab>
      <v-tab>Typography</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <color-panel />
      </v-tab-item>

      <v-tab-item>
        <button-panel />
      </v-tab-item>

      <v-tab-item>
        <form-panel />
      </v-tab-item>

      <v-tab-item>
        <spacing-panel />
      </v-tab-item>

      <v-tab-item>
        <typography-panel />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ColorPanel from '@/components/styleguide/ColorPanel';
import ButtonPanel from '@/components/styleguide/ButtonPanel';
import FormPanel from '@/components/styleguide/FormPanel';
import SpacingPanel from '@/components/styleguide/SpacingPanel';
import TypographyPanel from '@/components/styleguide/TypographyPanel';

export default {
  name: 'styleguide-view',
  components: {
    ColorPanel,
    ButtonPanel,
    FormPanel,
    SpacingPanel,
    TypographyPanel,
  },
  title: (args) => args.$t('page_title.styleguide'),
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card {
  padding: 20px;
}

::v-deep h2 {
  margin: 30px 0 16px;
}

::v-deep h3 {
  margin-bottom: 12px;
}
::v-deep .dynamic-value {
  font-weight: 600;
  color: rgba(26, 143, 200, 1);
  letter-spacing: 0.5px;
}
::v-deep .checkbox-container {
  display: flex;

  .v-input--checkbox {
    margin-right: 30px;
  }
}
</style>
