<template>
  <fragment>
    <span ref="valueNode" class="dynamic-value">
      {{ valueToDisplay }}
      <span v-if="valueOfVar != valueToDisplay" class="value-of-var"> - {{ valueOfVar }}</span>
    </span>

    <span ref="dummyNode" :style="'width: var(' + this.cssVar + '); display: none;'"></span>
  </fragment>
</template>

<script>
export default {
  name: 'css-var-value',
  props: {
    cssVar: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valueOfVar: '',
      computedValueOfVar: '',
    };
  },
  computed: {
    inlineStyle() {
      return 'width: var(' + this.cssVar + ')';
    },
    valueToDisplay() {
      return this.computedValueOfVar === 'auto' ? this.valueOfVar : this.computedValueOfVar;
    },
  },
  methods: {
    getValueOfVar() {
      let value = window.getComputedStyle(this.$refs.valueNode).getPropertyValue(this.cssVar);
      if (value === '') value = 'CSS Var not found';
      this.valueOfVar = value.trim();

      value = window.getComputedStyle(this.$refs.dummyNode).getPropertyValue('width');
      this.computedValueOfVar = value;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getValueOfVar();
    });
    window.addEventListener('resize', this.getValueOfVar);
  },
  unmounted() {
    window.removeEventListener('resize', this.getValueOfVar);
  },
};
</script>

<style lang="scss" scoped>
.value-of-var {
  opacity: 0.5;
}
</style>
