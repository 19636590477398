<template>
  <div>
    <h2 class="mb-2">Typography</h2>
    <v-card>
      <div>
        Your current breakpoint: <span class="dynamic-value">{{ $vuetify.breakpoint.name }}</span>
      </div>
      <div class="mb-4">
        Your current viewport width: <span class="dynamic-value">{{ $vuetify.breakpoint.width }}</span>
      </div>

      <h3 class="mb-2 mt-8">--HEADERS--</h3>

      <h1 class="mb-2">This is the H1 typography.</h1>
      <p class="mb-0">Automatically applied to H1 elements or with the "h-heading-h1" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-h1" /></p>

      <h2 class="mb-2">This is the H2 typography.</h2>
      <p class="mb-0">Automatically applied to H2 elements or with the "h-heading-h2" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-h2" /></p>

      <h3 class="mb-2">This is the H3 typography.</h3>
      <p class="mb-0">Automatically applied to H3 elements or with the "h-heading-h3" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-h3" /></p>

      <h4 class="mb-2">This is the H4 typography.</h4>
      <p class="mb-0">Automatically applied to H3 elements or with the "h-heading-h4" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-h4" /></p>

      <h3 class="mb-2 mt-8">--LABELS--</h3>

      <p class="mb-2 h-label-1">This is the Label 1 typography.</p>
      <p class="mb-0">Applied with the "h-label-1" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-1" /></p>

      <p class="mb-2 h-label-2">This is the Label 2 typography.</p>
      <p class="mb-0">Applied with the "h-label-2" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-2" /></p>

      <p class="mb-2 h-label-3-sb">This is the Label 3 - SB typography.</p>
      <p class="mb-0">Applied with the "h-label-3-sb" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-3" /></p>

      <p class="mb-2 h-label-3-bold">This is the Label 3 - Bold typography.</p>
      <p class="mb-0">Applied with the "h-label-3-bold" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-3" /></p>

      <p class="mb-2 h-label-4-bold">This is the Label 4 - Bold typography.</p>
      <p class="mb-0">Applied with the "h-label-4-bold" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-4" /></p>

      <p class="mb-2 h-label-4-med-italic">This is the Label 4 - Med Italic typography.</p>
      <p class="mb-0">Applied with the "h-label-4-med-italic" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-label-4" /></p>

      <h3 class="mb-2 mt-8">--Text--</h3>

      <p class="mb-2 h-text-1">This is the Text 1 typography.</p>
      <p class="mb-0">Applied with the "h-text-1" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-text-1" /></p>

      <p class="mb-2 h-text-2">This is the Text 2 typography.</p>
      <p class="mb-0">Applied with the "h-text-2" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-text-2" /></p>

      <p class="mb-2 h-text-3">This is the Text 3 typography.</p>
      <p class="mb-0">Applied with the "h-text-3" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-text-3" /></p>

      <p class="mb-2 h-text-4">This is the Text 4 typography.</p>
      <p class="mb-0">Applied with the "h-text-4" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-text-4" /></p>

      <h3 class="mb-2 mt-8">--OTHER--</h3>

      <p class="mb-2 h-caption">This is the Caption typography.</p>
      <p class="mb-0">Applied with the "h-caption" class.</p>
      <p class="mb-6">At your current breakpoint, it has a font-size of <css-var-value css-var="--font-size-caption" /></p>
    </v-card>
  </div>
</template>

<script>
import cssVarValue from '@/components/styleguide/cssVarValue';

export default {
  name: 'typography-panel',
  components: { cssVarValue },
};
</script>
